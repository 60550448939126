import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import banxa from '/assets/Icon/banxa-logo.svg'
import moonpay from '/assets/Icon/moonpay.png'
import information from '/assets/Icon/information.svg'
import CustomButton from 'components/Button/CustomButton'
import MoonpayModal from 'components/Modal/MoonpayModal'
import {
  BanxaOrderParams,
  BanxaCreateNFTOrderParams,
  banxaCreateCryptoOrder,
  banxaCreateNFTOrder
} from 'services/banxa.api.service'
import { useWallets } from 'providers/WalletsProvider'
import BanxaModal from 'components/Modal/BanxaModal'
import useExchangeRate from 'hooks/useExchangeRate'

const FEE = 0.03

interface PaymentProviderCardProps {
  packPrice?: string
  packId?: string
  isBanxaEnabled: boolean
  isBanxaTopUpEnabled: boolean
  isMoonpayEnabled: boolean
  isMoonpayTopUpEnabled: boolean
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  checkoutType: 'nft' | 'funds'
  handleClosePaymentModal: () => void
}

function PaymentProviderCard({
  packPrice = '0',
  packId = '',
  setActiveStep,
  isBanxaEnabled,
  isBanxaTopUpEnabled,
  isMoonpayEnabled,
  isMoonpayTopUpEnabled,
  checkoutType,
  handleClosePaymentModal
}: PaymentProviderCardProps) {
  const [moonpayModal, showMoonpayModal] = React.useState(false)
  const [banxaModal, showBanxaModal] = React.useState(false)
  const [expanded, setExpanded] = React.useState<string | false>(
    checkoutType === 'funds' ? 'panel1' : 'panel2'
  )
  const [banxaCheckoutURL, setBanxaCheckoutURL] = React.useState<string>('')
  const [topUpAmount, setTopUpAmount] = React.useState<number>(100)
  const { formatUSDPrice } = useExchangeRate()
  const { connectedWalletAddress } = useWallets()

  const totalPrice = packPrice && (parseFloat(packPrice) * (1 + FEE)).toFixed(2)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const closeMoonpayModal = () => {
    handleClosePaymentModal()
    showMoonpayModal(false)
  }

  const handleProviderClick = (providerName: string) => {
    if (providerName === 'Moonpay') return showMoonpayModal(true)

    const params: BanxaOrderParams = {
      walletAddress: connectedWalletAddress,
      sourceCurrency: 'AUD',
      targetCurrency: 'USDC',
      returnUrlOnSuccess: window.location.href
    }
    switch (checkoutType) {
      case 'nft':
        const nftParams: BanxaCreateNFTOrderParams = {
          ...params,
          tokenId: parseInt(packId || '')
        }
        banxaCreateNFTOrder(nftParams).then((response) => {
          if (response && response.iFrameCheckout) {
            setBanxaCheckoutURL(response.iFrameCheckout)
            showBanxaModal(true)
          }
        })
        break
      case 'funds':
        banxaCreateCryptoOrder(params).then((response) => {
          if (response && response.iFrameCheckout) {
            setBanxaCheckoutURL(response.iFrameCheckout)
            showBanxaModal(true)
          }
        })
        break
      default:
        console.error('Invalid checkout type')
        break
    }
  }

  const AccordionSummaryContent = ({
    providerName
  }: {
    providerName: string
  }) => {
    const isBanxa = providerName === 'Banxa'
    return (
      <div className="flex justify-between items-center w-full font-bold">
        <div className="flex gap-2 items-center">
          <img
            src={isBanxa ? banxa : moonpay}
            alt={`Provider icon`}
            className={`${isBanxa ? 'h-3' : 'h-4'}`}
          />
          <img
            onClick={() => {
              isBanxa ? setActiveStep(3) : setActiveStep(4)
            }}
            src={information}
            alt=""
            className="w-4 h-4"
          />
        </div>
        {checkoutType === 'nft' && <p>A${totalPrice}</p>}
      </div>
    )
  }

  const AccordionDetailsContent = ({
    providerName
  }: {
    providerName: string
  }) => {
    const isBanxa = providerName === 'Banxa'
    const isTopUp = checkoutType === 'funds'
    const initialAmount = parseFloat(packPrice)
    const processingFee = (initialAmount * FEE).toFixed(2)
    const totalPrice = (initialAmount * (1 + FEE)).toFixed(2)

    return (
      <div className="flex flex-col gap-2 items-start w-full ">
        {isTopUp ? (
          <p className="pb-4 text-xs text-left">
            {isBanxa
              ? `Banxa is a Web3 on-and-off ramp solution for payments. Banxa
            provides FIAT to USDC account top-ups for buying direct and bank
            card payments. Banxa will at random intervals require Identity
            verification (KYC) from buyers when using the AFL Mint website.`
              : `MoonPay is a financial technology company that builds
            payments infrastructure for crypto. Our on-and-off-ramp
            suite of products provides a seamless experience for
            converting between fiat currencies and cryptocurrencies
            using all major payment methods including debit and credit
            card, local bank transfers, Apple Pay, Google Pay, and
            Samsung Pay.`}
          </p>
        ) : (
          <>
            <div className="flex gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                <div className="text-xs">Pack</div>
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                <div className="text-xs font-bold leading-[18px]">
                  A${initialAmount}
                </div>
                <div className="text-xs leading-[18px] text-grey2">
                  ( USDC {formatUSDPrice(initialAmount)} )
                </div>
              </div>
            </div>
            <div className="flex gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                <div className="text-xs">Processing fee (3%)</div>
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                <div className="text-xs font-bold leading-[18px]">
                  A${processingFee}
                </div>
              </div>
            </div>
            <div className="flex gap-2 justify-between items-start pb-2 w-full">
              <div className="flex flex-col items-start">
                <div className="text-xs">Total</div>
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                <div className="text-xs font-bold leading-[18px]">
                  A${totalPrice}
                </div>
                <div className="text-xs leading-[18px] text-grey2">
                  ( USDC {formatUSDPrice(totalPrice)})
                </div>
              </div>
            </div>
          </>
        )}
        <CustomButton
          label={`Continue with ${providerName}`}
          model="primary"
          variant="contained"
          className="w-full"
          hasEndIcon={true}
          onClick={() => handleProviderClick(providerName)}
        />
      </div>
    )
  }

  const showBanxa =
    checkoutType === 'nft' ? isBanxaEnabled : isBanxaTopUpEnabled
  const showMoonpay =
    checkoutType === 'nft' ? isMoonpayEnabled : isMoonpayTopUpEnabled

  return (
    <div>
      <MoonpayModal
        open={moonpayModal}
        onClose={closeMoonpayModal}
        packId={packId}
        mode={checkoutType}
      />
      <BanxaModal
        open={banxaModal}
        onClose={() => showBanxaModal(false)}
        fullURL={banxaCheckoutURL}
      />
      <div className="py-4">
        {showBanxa && checkoutType !== 'nft' && (
          <Accordion
            sx={{
              backgroundColor: '#232324',
              color: '#fff',
              borderRadius: '3px'
            }}
            expanded={expanded === 'panel1'}
            defaultExpanded={true}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <AccordionSummaryContent providerName="Banxa" />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <AccordionDetailsContent providerName="Banxa" />
            </AccordionDetails>
          </Accordion>
        )}
        {showMoonpay && (
          <Accordion
            sx={{
              backgroundColor: '#232324',
              color: '#fff',
              borderRadius: '3px'
            }}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <AccordionSummaryContent providerName="Moonpay" />
            </AccordionSummary>
            <AccordionDetails>
              <AccordionDetailsContent providerName="Moonpay" />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  )
}

export default PaymentProviderCard
