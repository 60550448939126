import { useEffect, useState } from 'react'

import * as fcl from '@blocto/fcl'

import { useAuth } from 'providers/AuthProvider'
import {
  // claimCollectible,
  connectMagicWallet,
  getUserWallets,
  registerWallet
} from '../services/api.service'

import { Magic } from 'magic-sdk'
import { OpenIdExtension } from '@magic-ext/oidc'
import { FlowExtension } from '@magic-ext/flow'

import { useOktaAuth } from '@okta/okta-react'
import {
  getTotalListedMoments,
  getTotalNonListedMoments,
  getTotalMoments,
  getWUSDCBalanceForAccount
} from 'flow/helpers'
import useCollection from './useCollection'
import { RegisteredWallet } from 'types'
import { collectionUtils } from 'flow/helpers'

export default function useUserWallets() {
  // const featureFlags = useFeatureFlags()
  const { authState } = useOktaAuth()
  const { user } = useAuth()

  const [completedLoadingWalletsInfo, setCompletedLoadingWalletsInfo] =
    useState(false)
  const [registeredWallets, setRegisteredWallets] = useState<
    RegisteredWallet[]
  >([])
  const [lastUsedWallet, setLastUsedWallet] = useState(
    localStorage.getItem('lastUsedWallet') ?? 'unknown'
  )
  const [usingMagic, setUsingMagic] = useState(lastUsedWallet === 'magic')

  const [flowUser, setFlowUser] = useState<any>()
  const [flowWUSDCBalance, setFlowWUSDCBalance] = useState(0)
  const [connectedWalletAddress, setConnectedWalletAddress] = useState('')

  const { loading, createCollection, collection } = useCollection(flowUser)

  const [hasBlocto, setHasBlocto] = useState(false)
  const [hasMagic, setHasMagic] = useState(false)

  const [magicWalletWUSDCBalance, setMagicWalletWUSDCBalance] = useState(0)
  const [bloctoWalletWUSDCBalance, setBloctoWalletWUSDCBalance] = useState(0)

  const [bloctoWalletAddress, setBloctoWalletAddress] = useState('')
  const [magicWalletAddress, setMagicWalletAddress] = useState('')

  const [bloctoMomentCount, setBloctoMomentCount] = useState(0)
  const [magicMomentCount, setMagicMomentCount] = useState(0)

  const [magicForSaleMomentCount, setMagicForSaleMomentCount] = useState(0)
  const [bloctoForSaleMomentCount, setBloctoForSaleMomentCount] = useState(0)

  const [magicNotForSaleMomentCount, setMagicNotForSaleMomentCount] =
    useState(0)
  const [bloctoNotForSaleMomentCount, setBloctoNotForSaleMomentCount] =
    useState(0)

  const [isConnected, setIsConnected] = useState(false)
  const [userMetadata, setUserMetadata] = useState({})

  // const [doLoggedInRedirect, setDoLoggedInRedirect] = useState(false)
  // const [verifying, setVerifying] = useState(false)
  // const [message, setMessage] = useState('')

  const magic = new Magic(import.meta.env.VITE_MAGIC_API_KEY, {
    // testMode: true,
    extensions: [
      new OpenIdExtension(),
      new FlowExtension({
        rpcUrl: import.meta.env.VITE_ACCESS_NODE,
        network:
          import.meta.env.VITE_ENVIRONMENT === 'production'
            ? 'mainnet'
            : 'testnet'
      })
    ]
  })

  // const createCadenceOwnedAccount = async () => {
  //   const tx = await fcl.send([
  //     fcl.transaction(CREATE_EVM_ADDRESS_TX),
  //     fcl.args([

  //       )
  //     ]),
  //     fcl.proposer(userAuth),
  //     fcl.payer(userAuth),
  //     fcl.authorizations([userAuth]),
  //     fcl.limit(9999)
  //   ])
  //   fcl.send(      CREATE_EVM_ADDRESS_TX]

  const checkAndUpdateUsersRegisteredWallets = async () => {
    if (!user?.sub) return

    const registeredWallets: RegisteredWallet[] = await getUserWallets(
      user?.sub
    )
    setRegisteredWallets(registeredWallets)

    const magicWallets = registeredWallets.filter(
      (wallet: any) => wallet.isMagic
    )
    const bloctoWallets = registeredWallets.filter(
      (wallet: any) => !wallet.isMagic
    )

    const totalMagicWallets = registeredWallets.filter(
      (wallet: any) => wallet.isMagic
    ).length

    const userHasBloctoWallet = registeredWallets.length > totalMagicWallets
    const userHasMagicWallet = totalMagicWallets > 0

    if (userHasMagicWallet && magicWallets.length)
      setMagicWalletAddress(magicWallets[0].walletAddress)
    if (
      userHasBloctoWallet &&
      bloctoWallets.length &&
      bloctoWalletAddress.length === 0
    ) {
      const lastUsedBloctoAddress = localStorage.getItem(
        'lastUsedBloctoAddress'
      )
      setBloctoWalletAddress(
        lastUsedBloctoAddress ?? bloctoWallets[0].walletAddress
      )
    }

    setHasMagic(userHasMagicWallet)
    setHasBlocto(userHasBloctoWallet)
    await refreshWallets()
    setCompletedLoadingWalletsInfo(true)
  }

  // check registered wallets on okta authentication/connectedWallet change
  useEffect(() => {
    if (user?.sub) checkAndUpdateUsersRegisteredWallets()
  }, [user, user?.sub, connectedWalletAddress])

  // updates user wallet selection
  useEffect(() => {
    if (usingMagic) {
      try {
        magic.user.isLoggedIn().then(async (magicIsLoggedIn) => {
          setIsConnected(magicIsLoggedIn)
          if (magicIsLoggedIn) {
            const { publicAddress } = await magic.user.getMetadata()
            if (publicAddress) {
              setFlowUser({ addr: publicAddress })
              setConnectedWalletAddress(publicAddress)
              setMagicWalletAddress(publicAddress)
              setHasMagic(true)
              await registerWallet(publicAddress, user?.sub || '', true)
            }
            setUserMetadata(await magic.user.getMetadata())
            localStorage.setItem('lastUsedWallet', 'magic')
            setLastUsedWallet('magic')
          }
        })
      } catch (err) {
        // add papertrail(err)
        console.error(err)
      }
    } else {
      fcl.currentUser().subscribe(async (fclUser: any) => {
        if (fclUser?.addr) {
          setIsConnected(true)
          setFlowUser(fclUser)
          setUsingMagic(false)
          setBloctoWalletAddress(fclUser.addr)
          setConnectedWalletAddress(fclUser.addr)
          setHasBlocto(true)
          localStorage.setItem('lastUsedWallet', 'blocto')
          setLastUsedWallet('blocto')
          await registerWallet(fclUser.addr, user?.sub || '', false)
        } else {
          setFlowUser(undefined)
          setConnectedWalletAddress('')
        }
      })
    }
  }, [authState, usingMagic])

  // fetch user balances and register wallet with backend
  useEffect(() => {
    const registerAndCheckWallets = async () => {
      if (flowUser?.addr && user?.sub) {
        getFlowWUSDCBalance()

        // j00lz this should require signature and should check the existence of the linkage first
        // await checkAndUpdateUsersRegisteredWallets()
      } else {
        setFlowWUSDCBalance(0)
        setConnectedWalletAddress('')
        return
      }
    }
    registerAndCheckWallets()
    window.addEventListener('focus', getFlowWUSDCBalance)
    return () => {
      window.removeEventListener('focus', getFlowWUSDCBalance)
    }
  }, [flowUser?.addr, user, user?.sub, usingMagic])

  // lifecycle functions
  const loginMagic = async () => {
    return new Promise(async (resolve, reject) => {
      const oidc = authState?.idToken?.idToken
      let promise
      if (!oidc)
        promise = magic.auth.loginWithEmailOTP({ email: 'g_cahn@hotmail.com' })
      else
        promise = magic.openid.loginWithOIDC({
          jwt: oidc,
          providerId: import.meta.env.VITE_MAGIC_PROVIDER ?? ''
        })

      promise
        .on('done', async (result) => {
          if (result) {
            if (oidc) {
              const response = await connectMagicWallet(result)
            }
            const { publicAddress } = await magic.user.getMetadata()
            if (publicAddress) {
              const hasCollectionSetup = await collectionUtils.check(
                publicAddress
              )

              if (!hasCollectionSetup) {
                // create collection
                const createCollectionResult = await collectionUtils.create(
                  magic.flow?.authorization
                )
              }

              setIsConnected(true)
              setFlowUser({ addr: publicAddress })
              setConnectedWalletAddress(publicAddress)
              setMagicWalletAddress(publicAddress)
              setHasMagic(true)
              if (!user?.sub) return reject('no user sub')
            }
            setUserMetadata(await magic.user.getMetadata())
            localStorage.setItem('lastUsedWallet', 'magic')
            setLastUsedWallet('magic')
            setUsingMagic(true)
          }
          localStorage.setItem('lastUsedWallet', 'magic')
          setLastUsedWallet('magic')
          resolve(result)
        })
        .on('error', (reason) => {
          console.error('magic login error ', { reason })
          reject(reason)
        })
        .on('settled', () => {
          // This function will run when the promise has either been resolved or rejected.
        })
    })
  }

  const logoutMagic = async () => {
    await magic.user.logout()
    setIsConnected(false)
  }

  const authenticateBlocto = async () => {
    const authPromise: () => Promise<void> = () =>
      new Promise((resolve) => {
        fcl
          .authenticate()
          .then(async (status: any) => {
            if (status.loggedIn) {
              setUsingMagic(false)
              localStorage.setItem('lastUsedWallet', 'blocto')
              localStorage.setItem('lastUsedBloctoAddress', status.addr)
              setLastUsedWallet('blocto')
              setHasBlocto(true)
              setIsConnected(true)
              await registerWallet(status.addr, user?.sub || '', false)
            }
          })
          .finally(async () => {
            await checkAndUpdateUsersRegisteredWallets()
            resolve()
          })
      })
    await authPromise()
  }

  const getMagicIds = async () => {
    if (!magicWalletAddress) return
    setMagicMomentCount(await getTotalMoments(magicWalletAddress))
    setMagicForSaleMomentCount(await getTotalListedMoments(magicWalletAddress))
    setMagicNotForSaleMomentCount(
      await getTotalNonListedMoments(magicWalletAddress)
    )
  }

  const getMagicWUSDCBalance = async () => {
    if (!magicWalletAddress) return
    const res = await getWUSDCBalanceForAccount(magicWalletAddress)
    setMagicWalletWUSDCBalance(res ?? 0)
  }
  const getBloctoIds = async () => {
    setBloctoMomentCount(await getTotalMoments(bloctoWalletAddress))
    setBloctoForSaleMomentCount(
      await getTotalListedMoments(bloctoWalletAddress)
    )
    setBloctoNotForSaleMomentCount(
      await getTotalNonListedMoments(bloctoWalletAddress)
    )
  }
  const getBloctoWUSDCBalance = async () => {
    const res = await getWUSDCBalanceForAccount(bloctoWalletAddress)
    setBloctoWalletWUSDCBalance(res ?? 0)
  }

  const refreshMagicWalletInfo = async () => {
    await getMagicIds()
    await getMagicWUSDCBalance()
  }
  const refreshBloctoWalletInfo = async () => {
    await getBloctoIds()
    await getBloctoWUSDCBalance()
  }
  const refreshWallets = async () => {
    await refreshBloctoWalletInfo()
    await refreshMagicWalletInfo()
  }

  useEffect(() => {
    if (magicWalletAddress) {
      getMagicIds()
      getMagicWUSDCBalance()
    }
  }, [magicWalletAddress])

  // check wallet balances
  useEffect(() => {
    if (bloctoWalletAddress) {
      getBloctoIds()
      getBloctoWUSDCBalance()
    }
  }, [bloctoWalletAddress])

  // connection handlers
  const handleConnect = async () => {
    if (usingMagic) {
      await loginMagic()
    } else {
      await authenticateBlocto()
    }
  }

  const handleDisconnect = () => {
    if (usingMagic) {
      logoutMagic()
    } else {
      fcl.unauthenticate()
      setIsConnected(false)
    }
  }

  const getFlowWUSDCBalance = async () => {
    if (!flowUser?.addr) return
    try {
      const res = await getWUSDCBalanceForAccount(flowUser?.addr)
      setFlowWUSDCBalance(Number(res ?? 0))
      if (usingMagic) {
        setMagicWalletWUSDCBalance(Number(res ?? 0))
      } else {
        setBloctoWalletWUSDCBalance(Number(res ?? 0))
      }
    } catch (err) {
      // add papertrail(err)
      console.error(err)
      setFlowWUSDCBalance(0)
    }
  }

  const showMagicWalletUI = async () => {
    try {
      await magic.wallet.showUI()
      const magicIsLoggedIn = await magic.user.isLoggedIn()
      // If user is not logged in, set isConnected to false
      if (!magicIsLoggedIn) {
        setIsConnected(false)
      }
    } catch (error) {
      // Handle 'error' where user rejects the action (closes the modal) by logging a message
      if (
        (error as Error).message ===
        'Magic RPC Error: [-32603] User rejected the action'
      ) {
        console.info('User dismissed the modal')
      } else {
        console.error('error from showUI', error)
      }
    }
  }

  return {
    registeredWallets,
    flowUser,
    hasBlocto,
    hasMagic,
    magicWalletAddress,
    bloctoWalletAddress,
    magicMomentCount,
    bloctoMomentCount,
    connectedWalletAddress,
    isConnected,
    tools: {
      connect: handleConnect,
      disconnect: handleDisconnect
    },
    connectBlocto: authenticateBlocto,
    connectMagic: loginMagic,
    flowWUSDCBalance,
    magicWalletWUSDCBalance,
    bloctoWalletWUSDCBalance,
    getFlowWUSDCBalance,
    magic,
    userAuth: usingMagic ? magic.flow?.authorization : fcl.authz,
    userMetadata,
    usingMagic,
    setUsingMagic,
    completedLoadingWalletsInfo,
    collection,
    collectionIsLoading: loading,
    createCollection,
    lastUsedWallet,
    checkAndUpdateUsersRegisteredWallets,
    refreshWallets,
    magicForSaleMomentCount,
    bloctoForSaleMomentCount,
    magicNotForSaleMomentCount,
    bloctoNotForSaleMomentCount,
    showMagicWalletUI
    // doLoggedInRedirect
  }
}
