import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import CustomButton from 'components/Button/CustomButton'
import useExchangeRate from 'hooks/useExchangeRate'
import { useSnackbar } from 'hooks/useSnackbar'
import bloctoIcon from '/assets/Icon/blocto.svg'
import magicIcon from '/assets/Icon/Logo-Magic.svg'
import { useWallets } from 'providers/WalletsProvider'
import SetupWalletModal from 'components/Modal/SetupWalletModal'
import SpinnerModal from 'components/Modal/SpinnerModal'
import PaymentModal from 'components/Modal/PaymentModal'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useEVM from 'hooks/useEVM'

const GET_BLOCTO_APP_URL = 'https://blocto.io/download'

interface WalletCardProps {
  walletName: 'Magic' | 'Blocto'
  showForSaleBreakdown?: boolean
  showEVM?: boolean
}

function WalletCard({
  walletName,
  showForSaleBreakdown = false,
  showEVM = false
}: WalletCardProps) {
  const { openSnackbar } = useSnackbar()
  const { rate } = useExchangeRate()
  const { isBanxaTopUpEnabled, isMoonpayTopUpEnabled } = useFeatureFlags()
  const {
    hasMagic,
    hasBlocto,
    magicWalletAddress,
    bloctoWalletAddress,
    connectedWalletAddress,
    magicWalletWUSDCBalance,
    bloctoWalletWUSDCBalance,
    magicMomentCount,
    bloctoMomentCount,
    isConnected,
    connectMagic,
    connectBlocto,
    disconnect,
    usingMagic,
    magicForSaleMomentCount,
    bloctoForSaleMomentCount,
    magicNotForSaleMomentCount,
    bloctoNotForSaleMomentCount,
    showMagicWalletUI
  } = useWallets()
  const [spinner, setSpinner] = useState(false)
  const [setupWalletModalOpen, setSetupWalletModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [copiedFlow, setCopiedFlow] = useState(false)
  const [copiedEVM, setCopiedEVM] = useState(false)

  const { createCadenceOwnedAccount, bloctoEVMAddress, magicEVMAddress, evmUSDBalance, transferFromEVM, transferToEVM } = useEVM()

  const handleCreateEVMAddress = async () => {
    createCadenceOwnedAccount()
  }

  const isMagic = walletName === 'Magic'
  const hasAddedWallet = isMagic ? hasMagic : hasBlocto
  const walletIcon = isMagic ? magicIcon : bloctoIcon
  const totalCollectiblesInAccount = isMagic
    ? magicMomentCount
    : bloctoMomentCount
  const forSaleCollectiblesInAccount = isMagic
    ? magicForSaleMomentCount
    : bloctoForSaleMomentCount
  const notForSaleCollectiblesInAccount = isMagic
    ? magicNotForSaleMomentCount
    : bloctoNotForSaleMomentCount
  const walletAddress = isMagic ? magicWalletAddress : bloctoWalletAddress
  const _isConnected = isMagic
    ? usingMagic && isConnected
    : !usingMagic && isConnected
  const flowWUSDCBalance = isMagic
    ? magicWalletWUSDCBalance ?? magicWalletWUSDCBalance
    : bloctoWalletWUSDCBalance ?? bloctoWalletWUSDCBalance

  const evmAddress = isMagic ? magicEVMAddress : bloctoEVMAddress

  const userBalanceAUD = Number(flowWUSDCBalance * rate).toFixed(2)
  const USDCBalance = Number(flowWUSDCBalance).toFixed(2)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConnectClick = () => {
    if (!_isConnected && !hasAddedWallet) {
      setSetupWalletModalOpen(true)
      return
    }

    if (isMagic) {
      handleMagicConnect()
    } else {
      handleBloctoConnect()
    }
    handleClose()
  }

  const handleMagicConnect = () => {
    if (!_isConnected) {
      connectMagic()
      setSpinner(true)
    } else {
      disconnect()
    }
  }

  const handleBloctoConnect = () => {
    if (!_isConnected) {
      connectBlocto()
    } else {
      disconnect()
    }
  }

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(walletAddress)
    setCopiedFlow(true)
    setTimeout(() => setCopiedFlow(false), 2000)
    openSnackbar('Flow address copied to clipboard')
  }

  const copyEVMWalletAddress = () => {
    if (!evmAddress) {
      openSnackbar('EVM Address not created yet')
      return
    }
    navigator.clipboard.writeText(evmAddress)
    setCopiedEVM(true)
    setTimeout(() => setCopiedEVM(false), 2000)
    openSnackbar('EVM address copied to clipboard')
  }

  const handleOpenPaymentModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (isConnected || connectedWalletAddress === magicWalletAddress) {
      setSpinner(false)
    }
  }, [connectedWalletAddress, magicWalletAddress, isConnected])

  const showAddFundsButton =
    _isConnected && (isBanxaTopUpEnabled || isMoonpayTopUpEnabled)

  const truncateAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`
  }

  return (
    <div className="flex flex-col gap-2 justify-between p-4 w-full bg-card rounded-[3px]">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex justify-between items-center w-full font-bold">
          <div className="flex gap-2 items-center">
            <img
              src={walletIcon}
              alt={`Logo ${walletName}`}
              className="w-[20px] h-6"
            />
            <div className="text-base text-white">{walletName}</div>
          </div>
          <Switch
            sx={{
              padding: '6px',
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#ffffff'
              },
              '& .MuiSwitch-track': {
                borderRadius: '16px',
                backgroundColor: '#7E7E7E'
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#48a0ff'
              }
            }}
            checked={_isConnected}
            onChange={handleConnectClick}
          />
        </div>

        <div className="flex gap-2 mt-2 w-full">
          <div className="flex-1 bg-[#2a2a2a] rounded p-2">
            <div className="flex justify-between items-center mb-1">
              <Tooltip title="Your Flow addresses is your main address for the Flow blockchain.">
                <span className="text-xs font-semibold text-[#a6a6a6]">Flow Address</span>
              </Tooltip>
              <button
                className={`text-xs px-2 py-1 rounded ${copiedFlow ? 'bg-green-500 text-white' : 'bg-[#3a3a3a] text-[#a6a6a6] hover:bg-[#4a4a4a]'}`}
                onClick={copyWalletAddress}
              >
                {copiedFlow ? 'Copied!' : 'Copy'}
              </button>
            </div>
            <div className="text-sm text-white break-all">
              {showEVM ? truncateAddress(walletAddress) : walletAddress}
            </div>
          </div>

          {/* Transfer from EVM Left Arrow */}
          {showEVM && (
            <div className="flex flex-col justify-center items-center">
              <Tooltip title="Transfer from Flow-EVM to your main Flow Wallet">
                <button
                  className="text-lg text-[#48a0ff] hover:underline"
                  onClick={transferFromEVM}
                >
                  &larr;
                </button>
              </Tooltip>

              {/* Transfer to EVM Right Arrow */}
              {/* <button
              className="text-lg text-[#48a0ff] hover:underline"
              onClick={transferToEVM}
            >
              &rarr;
            </button> */}
            </div>
          )}

          {showEVM && evmAddress ? (
            <div className="flex-1 bg-[#2a2a2a] rounded p-2">
              <div className="flex justify-between items-center mb-1">
                <Tooltip title="Your EVM addresses allows receiving tokens via Flow-EVM web3 integrations.">
                  <span className="text-xs font-semibold text-[#a6a6a6]">EVM Address</span>
                </Tooltip>
                <button
                  className={`text-xs px-2 py-1 rounded ${copiedEVM ? 'bg-green-500 text-white' : 'bg-[#3a3a3a] text-[#a6a6a6] hover:bg-[#4a4a4a]'}`}
                  onClick={copyEVMWalletAddress}
                >
                  {copiedEVM ? 'Copied!' : 'Copy'}
                </button>
              </div>
              <div className="text-sm text-white break-all">
                {truncateAddress(evmAddress)}
              </div>
            </div>
          ) : showEVM && _isConnected && (
            <div className="flex-1 bg-[#2a2a2a] rounded p-2 flex items-center justify-center">
              <button
                className="text-sm text-[#48a0ff] hover:underline"
                onClick={handleCreateEVMAddress}
              >
                Create EVM Address
              </button>
            </div>
          )}
        </div>

        {hasAddedWallet === false && (
          <div className="flex flex-col gap-2 items-start py-2 w-full border-t border-[#3e3e3e]">
            <div className="flex gap-2 justify-between items-start w-full text-[#d3d3d3]">
              {isMagic
                ? 'Magic is a new wallet option that reduces log in and purchase steps for a more seamless experience.'
                : 'Blocto wallet is one of the most secure and user-friendly wallets available on the Flow network.'}
            </div>
          </div>
        )}
        {hasAddedWallet && (
          <div className="flex flex-col gap-2 items-start py-2 w-full border-t border-[#3e3e3e]">
            <div className="flex gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                {showForSaleBreakdown && (
                  <>
                    <div className="text-sm font-bold">
                      Collectibles (not for sale)
                    </div>
                    <div className="text-sm font-bold">
                      Collectibles (for sale)
                    </div>
                  </>
                )}
                {!showForSaleBreakdown && (
                  <div className="text-sm font-bold">Collectibles</div>
                )}
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                {showForSaleBreakdown && (
                  <>
                    <div className="font-bold">
                      {notForSaleCollectiblesInAccount}
                    </div>
                    <div className="font-bold">
                      {forSaleCollectiblesInAccount}
                    </div>
                  </>
                )}
                {!showForSaleBreakdown && (
                  <div className="font-bold">{totalCollectiblesInAccount}</div>
                )}
              </div>
            </div>
            <div className="flex relative gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                <div className="text-sm font-bold">Balance</div>
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                <div className="font-bold">A${userBalanceAUD}</div>
                <div className="text-grey2">({USDCBalance} USDC)</div>

                { /* Show EVM USDC Balance if Available */}
                {evmUSDBalance && (
                  <div className="text-grey2">
                    {`${evmUSDBalance} WUSDC (EVM)`}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {_isConnected ? (
        <>
          {showAddFundsButton && (
            <CustomButton
              label="Add funds"
              model="primary"
              variant="contained"
              onClick={handleOpenPaymentModal}
            />
          )}
        </>
      ) : (
        <CustomButton
          label={hasAddedWallet ? 'Connect Wallet' : 'Get Started'}
          model="secondary"
          variant="contained"
          onClick={handleConnectClick}
        />
      )}
      {_isConnected && isMagic && (
        <CustomButton
          label="Manage Funds"
          model="secondary"
          variant="outlined"
          onClick={() => showMagicWalletUI()}
        />
      )}
      {_isConnected && !isMagic && (
        <a href={GET_BLOCTO_APP_URL} target="_blank" rel="noreferrer">
          <CustomButton
            label="Manage Funds (Get App)"
            model="secondary"
            variant="outlined"
            isExternalLink={true}
            className="w-full"
          />
        </a>
      )}

      <SetupWalletModal
        isMagic={isMagic}
        setupWalletModalOpen={setupWalletModalOpen}
        setSetupWalletModalOpen={setSetupWalletModalOpen}
      />
      <SpinnerModal
        spinnerModalOpen={spinner}
        setSpinnerModalOpen={setSpinner}
      />
      <PaymentModal
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        updateActiveStep={2}
        checkoutType="funds"
      />
    </div>
  )
}
export default WalletCard
