import { config } from '@blocto/fcl'

config({
  'accessNode.api': import.meta.env.VITE_ACCESS_NODE,
  'discovery.wallet': import.meta.env.VITE_WALLET_DISCOVERY_FALLBACK,
  'discovery.authn.endpoint': import.meta.env.VITE_WALLET_DISCOVERY,
  'discovery.wallet.method': 'HTTP/POST',
  'app.detail.title': 'AFL Mint',
  'app.detail.icon':
    'https://afl-mint-production.s3.ap-southeast-2.amazonaws.com/public/general/android-chrome-192x192.png',
  '0xAFLContracts': import.meta.env.VITE_AFL_CONTRACTS,
  '0xUSDCFlow': import.meta.env.VITE_USDCFLOW_CONTRACT,
  '0xMetadataViews': import.meta.env.VITE_METADATAVIEWS,
  '0xFungibleToken': import.meta.env.VITE_FUNGIBLE_CONTRACT,
  '0xNFT': import.meta.env.VITE_NFT_CONTRACT,
  '0xFlow': import.meta.env.VITE_FLOW_CONTRACT,
  '0xEVM': import.meta.env.VITE_EVM_CONTRACT,
  '0xViewResolver': import.meta.env.VITE_VIEW_RESOLVER,
  '0xFTMetadataViews': import.meta.env.VITE_FT_METADATA_VIEWS,
  '0xEVMBridgeContract': import.meta.env.VITE_EVM_BRIDGE_CONTRACT,
  '0xScopedFTProviders': import.meta.env.VITE_SCOPED_FT_PROVIDERS
})

// persist session across logins not working for some reason
//
// const isServerSide = () => typeof window === 'undefined'
// const LOCAL_STORAGE = {
//   can: !isServerSide(),
//   get: async (key: string): Promise<string> =>
//     JSON.parse(localStorage.getItem(key) ?? ''),
//   put: async (key: string, value: any) =>
//     localStorage.setItem(key, JSON.stringify(value))
// }
// config.put('fcl.storage', LOCAL_STORAGE)
