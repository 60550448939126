import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'
import AuthProvider from 'providers/AuthProvider'
import { Buffer } from 'buffer'
import 'styles/tailwind.css'
import './flow/config'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import WalletsProvider from 'providers/WalletsProvider'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import TransactionsProvider from 'providers/TransactionsProvider'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { SnackbarProvider } from 'providers/SnackbarProvider'
import MaintenancePage from 'pages/main/MaintenancePage'
import { getSiteSettings } from 'services/api.service'
window.Buffer = Buffer

// const oktaAuth = new OktaAuth({
//   issuer: import.meta.env.VITE_OKTA_ISSUER,
//   clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
//   redirectUri: import.meta.env.VITE_OKTA_REDIRECT_URI
// })

const oktaAuth = new OktaAuth({
  issuer: import.meta.env.VITE_OKTA_ISSUER,
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback'
})

const history = window.location

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  history.replace(
    toRelativeUrl(originalUri || '/get-started', window.location.origin)
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)
ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID, {
  testMode: import.meta.env.VITE_ENVIRONMENT === 'develop'
})

let maintenanceMode = false

const fetchSiteSettings = async () => {
  try {
    const siteSettings = await getSiteSettings()
    maintenanceMode = siteSettings.maintenanceMode ?? false
  } catch (error) {
    console.error('Failed to fetch site settings:', error)
    maintenanceMode = false
  }
}

root.render(
  <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
    <AuthProvider>
      <TransactionsProvider>
        <WalletsProvider>
          <BrowserRouter>
            <SnackbarProvider>
              {maintenanceMode ? <MaintenancePage /> : <App />}
            </SnackbarProvider>
          </BrowserRouter>
        </WalletsProvider>
      </TransactionsProvider>
    </AuthProvider>
  </Security>
)

fetchSiteSettings()
