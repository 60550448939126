import { useState, useEffect } from 'react'
import { marketplaceEarlyAccessUsers } from '../utils/earlyAccessList'
import { useWallets } from 'providers/WalletsProvider'
import { ISiteSettings } from '../types'
import { getSiteSettings } from 'services/api.service'

const ADMIN_PERMISSION_KEY = 'adminPermissionAFLAdminDevTester'
const IS_MOONPAY_LIVE_KEY = 'IS_MOONPAY_LIVE'
const IS_BANXA_LIVE_KEY = 'IS_BANXA_LIVE'
const IS_MOONPAY_TOP_UP_ENABLED = 'IS_MOONPAY_TOP_UP_ENABLED'
const IS_BANXA_TOP_UP_ENABLED_KEY = 'IS_BANXA_TOP_UP_ENABLED'

export const useFeatureFlags = () => {
  const { flowUser } = useWallets()
  const [siteSettings, setSiteSettings] = useState<ISiteSettings | null>(null)

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const siteSettings: ISiteSettings = await getSiteSettings()
        setSiteSettings(siteSettings)
      } catch (error) {
        console.error('Failed to fetch site settings:', error)
        setSiteSettings(null)
      }
    }

    fetchMaintenanceData()
  }, [])

  const adminPermission = localStorage.getItem(ADMIN_PERMISSION_KEY)

  const USE_MAGIC_WALLET = import.meta.env.VITE_USE_MAGICWALLET === 'true'

  const MARKETPLACE_ACTIVE = import.meta.env.VITE_ENABLE_MARKETPLACE === 'true'

  const PRIZE_DRAW_ACTIVE = import.meta.env.VITE_ENABLE_PRIZE_DRAW === 'true'

  const MARKETPLACE_PRODUCTINFO_POPUP_ACTIVE =
    import.meta.env.VITE_ENABLE_MARKETPLACE_INFO_POPUP === 'true'

  const MARKETPLACE_FEE = parseFloat(
    import.meta.env.VITE_MARKETPLACE_FEE ?? '0.08'
  )

  const DUPLICATES_SWAP_ENABLED =
    import.meta.env.VITE_ENABLE_DUPLICATES_SWAP === 'true'

  // Checkout related flags
  // Banxa
  const isBanxaEnabled =
    siteSettings?.filter((setting) => setting.key === IS_BANXA_LIVE_KEY)[0]
      ?.value === true || adminPermission === 'true'

  const isBanxaTopUpEnabled =
    siteSettings?.filter(
      (setting) => setting.key === IS_BANXA_TOP_UP_ENABLED_KEY
    )[0]?.value === true || adminPermission === 'true'

  // Moonpay
  const isMoonpayEnabled =
    siteSettings?.filter((setting) => setting.key === IS_MOONPAY_LIVE_KEY)[0]
      ?.value === true || adminPermission === 'true'

  const isMoonpayTopUpEnabled =
    siteSettings?.filter(
      (setting) => setting.key === IS_MOONPAY_TOP_UP_ENABLED
    )[0]?.value === true || adminPermission === 'true'

  const isCardPaymentsEnabled = isBanxaEnabled || isMoonpayEnabled

  return {
    USE_MAGIC_WALLET,
    MARKETPLACE_ACTIVE,
    MARKETPLACE_FEE,
    MARKETPLACE_PRODUCTINFO_POPUP_ACTIVE,
    DUPLICATES_SWAP_ENABLED,
    PRIZE_DRAW_ACTIVE,
    isMoonpayEnabled,
    isBanxaEnabled,
    isBanxaTopUpEnabled,
    isMoonpayTopUpEnabled,
    isCardPaymentsEnabled
  }
}
