export const GET_ASSOCIATED_EVM_ADDRESS = `
import EVM from 0xEVM

import FlowEVMBridgeConfig from 0xEVMBridgeContract
import USDCFlow from 0xUSDCFlow

/// Returns the EVM address associated with the given Cadence type (as its identifier String)
///
/// @param typeIdentifier: The Cadence type identifier String
///
/// @return The EVM address as a hex string if the type has an associated EVMAddress, otherwise nil
///
access(all)
fun main(identifier: String): String? {
    if let type = CompositeType(identifier) {
        if let address = FlowEVMBridgeConfig.getEVMAddressAssociated(with: type) {
            return address.toString()
        }
    }
    return nil
}
`
